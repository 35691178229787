<template>
    <svg width="22px" height="16px" viewBox="0 0 22 16" version="1.1" xmlns="http://www.w3.org/2000/svg" xmlns:xlink="http://www.w3.org/1999/xlink">
        <!-- Generator: sketchtool 63.1 (101010) - https://sketch.com -->
        <title>FB136965-0594-4CDB-BE21-8EEA1F417BC4</title>
        <desc>Created with sketchtool.</desc>
        <g id="Page-1" stroke="none" stroke-width="1" fill="none" fill-rule="evenodd">
            <g id="Spec" transform="translate(-987.000000, -650.000000)" :fill="isThemeDark ? '#000000' : '#fff'" fill-rule="nonzero">
                <g id="tv" transform="translate(987.300000, 649.657394)">
                    <path d="M19.1562968,0.413793103 C20.0940953,0.413793103 20.8574595,1.17671875 20.8574595,2.11495582 L20.8574595,2.11495582 L20.8574595,12.3218524 C20.8574595,13.2600894 20.0940953,14.0230151 19.1562968,14.0230151 L19.1562968,14.0230151 L11.9264251,14.0230151 L11.9264251,14.8735765 L14.4781492,14.8735765 C14.7132268,14.8735765 14.9034498,15.0637996 14.9034498,15.2988772 C14.9034498,15.5339547 14.7132268,15.7241778 14.4781492,15.7241778 L14.4781492,15.7241778 L6.82297678,15.7241778 C6.58789919,15.7241778 6.39767613,15.5339547 6.39767613,15.2988772 C6.39767613,15.0637996 6.58789919,14.8735765 6.82297678,14.8735765 L6.82297678,14.8735765 L9.37470091,14.8735765 L9.37470091,14.0230151 L2.14482915,14.0230151 C1.20703066,14.0230151 0.443666432,13.2600496 0.443666432,12.3218524 L0.443666432,12.3218524 L0.443666432,2.11495582 C0.443666432,1.17671875 1.20703066,0.413793103 2.14482915,0.413793103 L2.14482915,0.413793103 Z M11.0758636,14.0230151 L10.2253022,14.0230151 L10.2253022,14.8735765 L11.0758636,14.8735765 L11.0758636,14.0230151 Z M18.3346265,1.78081383 L2.96649945,1.78081383 C2.4142147,1.78081383 1.96649945,2.22852908 1.96649945,2.78081383 L1.96649945,2.78081383 L1.96649945,11.4450192 C1.96649945,11.997304 2.4142147,12.4450192 2.96649945,12.4450192 L2.96649945,12.4450192 L18.3346265,12.4450192 C18.8869113,12.4450192 19.3346265,11.997304 19.3346265,11.4450192 L19.3346265,11.4450192 L19.3346265,2.78081383 C19.3346265,2.22852908 18.8869113,1.78081383 18.3346265,1.78081383 L18.3346265,1.78081383 Z" id="Combined-Shape"></path>
                </g>
            </g>
        </g>
    </svg>
</template>

<script>
import { eventBus } from "@/eventBus";
export default {
    name: "Tvsvg",
    data() {
        return{
            isThemeDark: true,
        }
    },
    created() {
        eventBus.$on("theme-change", (data) =>{
            if(data == 'theme-dark'){
                this.isThemeDark = true
            }else{
                this.isThemeDark = false
            }
        })
    },
    mounted() {        
    let currentTheme = localStorage.getItem('theme');
    if(currentTheme == 'theme-light'){
      this.isThemeDark = false
    }else{
      this.isThemeDark = true
    }
    },
  };
</script>

<style>
</style>